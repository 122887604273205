import React from "react"
import Helmet from "react-helmet"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Title from "../components/title"
import Back from "../components/back"

export default function Home() {
  return (
    <>
      <Helmet>
        <html lang="ja"></html>
        <title>お問い合わせありがとうございました</title>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <div className="flex flex-col h-screen">
        <header>
          <Navbar hasContact={false} />
        </header>
        <main className="mt-14 reveal fade-bottom">
          <div className="container max-w-5xl mx-auto py-4 px-4">
            <Title title="送信完了" />

            <div className="bg-white rounded-lg mb-4 flex flex-wrap">
              <div className="w-full md:w-1/2 p-6">
                <img src="/thank-you-1428147_640.png" alt="サンクスカード" />
              </div>
              <div className="w-full md:w-1/2 p-6">
                <p className="text-lg mt-4 md:mt-8 text-gray-600 leading-10">お問い合わせいただきありがとうございました。<br />担当者が内容を確認し、後ほどご返信させて頂きますので、お待ちくださいませ。</p>
                <Back />
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  )
}
